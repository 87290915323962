.login-dialog-container {
  & > div {
    & > div {
      .body {
        .sign-up-container {
          flex: 1;
          padding: 17px 0 17px 50px;
          border-left: 1px solid #d9dde0;

          .terms-agree-container {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            input {
              margin: 4px 7px 0 0;
              cursor: pointer;
              width: 12px;
            }
            .accept-terms-info {
              flex: 1;
              font-size: 11px;
              label {
                color: #5e737d;
                p {
                  margin: 0 0 2px;
                }
              }
              a {
                color: #2d4d80;
                font-weight: 600;
                text-decoration: none;
              }
            }
          }
        }
        & > div {
          .do-another-action {
            display: none;
          }

          h2 {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin: 0 0 4px 0;
            color: #3a484e;
            text-transform: uppercase;
          }

          .info {
            margin: 0 0 15px 0;
            font-size: 14px;
            color: #5c717b;
          }

          .button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 18px;

            .btn {
              height: 36px;
              padding: 1px 0 0 0;
              width: 110px;

              &.btn-default {
                display: none;
              }
            }
          }
        }
      }

      .close {
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/img/close-dialog-icon.svg');
        background-size: 100%;
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .login-dialog-container {
    & > div {
      & > div {
        width: calc(100% - 40px);
        padding: 13px 20px;
        margin: 0;
        border-radius: 0;

        .body {
          & > div {
            &.sign-up-container {
              flex: none;
              border-left: 0;
              border-top: 1px solid #d9dde0;
              padding: 30px 0 25px 0;
            }

            .do-another-action {
              font-size: 13px;
              text-align: center;
              color: #5c717b;
              margin-top: 18px;
              margin-bottom: 0;
              display: block;

              .link {
                text-transform: uppercase;
                font-weight: bold;
              }
            }

            .button-container {
              display: flex;
              flex-direction: row;
              margin-top: 18px;

              .btn {
                flex: 1;
                height: 40px;
                padding: 1px 0 0 0;

                &.btn-default {
                  display: block;
                }
              }
            }
          }
        }

        .close {
          display: none;
        }
      }
    }
  }
}
