.session-expired-dialog-container {
  & > div {
    & > div {
      padding: 20px 22px 25px;
      box-sizing: border-box;
      position: relative;
      overflow: initial;

      .body {
        margin-bottom: 20px;
      }
    }
  }
}
