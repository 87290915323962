.header-container {
  position: fixed;
  z-index: 900;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  border-bottom: 1px solid #d8d8d8;

  .header {
    height: 60px;
    width: 1050px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo {
      background-size: contain;
      width: 130px;
      height: 40px;
      background-repeat: no-repeat;
    }

    .redirect-link {
      color: #2d4d80;
      display: flex;
      border-radius: 3.5px;
      background-color: #ffffff;
      margin-right: 20px;
      text-transform: none;
      font-family: 'Open Sans' !important;
      font-weight: 600;
      font-size: 12px;
      border-color: #2d4d80;
    }

    .dropdown-container {
      display: flex;
      flex-direction: row;
      .link {
        font-size: 16px;
        font-weight: 600;
        color: #3a484e;
        position: relative;
        padding-right: 23px;
        height: 36px;
        line-height: 36px;
        display: inline-block;
        white-space: nowrap;

        &:after {
          content: ' ';
          display: block;
          width: 13px;
          height: 7px;
          position: absolute;
          right: 0;
          top: 15px;
          background-image: url('../../assets/img/down-arrow-icon.svg');
          background-size: 100%;
        }
      }
      .btn {
        min-width: 110px;
        height: 35px;
        padding: 0 7px;
        align-items: center;
        display: flex;
        box-sizing: border-box;

        &.book-delivery-link {
          display: none;
          padding-right: 10px;
          padding-left: 10px;
          margin-right: 20px;
          font-size: 14px;
        }
      }

      .menu-container {
        width: 129px;
        border-radius: 2px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        padding: 5px;

        & > li {
          height: 35px;
          border-radius: 4px;
          padding: 0 7px;
          margin-bottom: 2px;
          font-size: 13px;
          font-weight: 600;
          color: #3a484e;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            background-color: #edf1f3;
          }

          & > a {
            color: #3a484e;
            text-decoration: none;
            width: 100%;
            display: block;
            line-height: 35px;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: -7px;
          right: 0;
          border-style: solid;
          border-width: 0 6.5px 7px 6.5px;
          border-color: transparent transparent #ffffff transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .header-container {
    .header {
      width: calc(100% - 30px);
      height: 60px;

      .logo {
        width: 87px;
        height: 40px;
      }
      .dropdown-container {
        .btn {
          font-size: 14px;
          height: 31px;
          min-width: 90px;
          padding: 0 7px;
        }
        .menu-container {
          &:after {
            right: 11px;
          }
        }
      }
    }
  }
}
