body {
  background-color: #ffffff;
  font-family: 'Open Sans';

  // Normalize
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    padding-top: 60px;
    min-height: 100vh;

    .banner-container {
      height: 150px;
      background-attachment: scroll;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .content-container {
      position: relative;
      width: 1050px;
      margin: 0 auto;
      overflow: hidden;
    }

    .footer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 15px 0;
    }
    &.payment-page {
      .content-container {
        width: 780px;
      }
    }
  }

  // Form Elements
  .form-group {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    &:focus-within {
      label {
        font-weight: bold;
        color: #3b5e6e;
      }
      & > .form-control {
        & > .field {
          border-color: #3b5e6e;
        }
        &.postfix {
          & > .fix {
            border-color: #3b5e6e;
          }
        }
      }
    }

    label {
      font-size: 12px;
      font-weight: 600;
      color: #5e737d;
      margin-bottom: 5px;
    }

    .label-control {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .link {
        font-size: 11px;
        font-weight: 600;
        color: #2d4d80;
      }
    }

    .form-control {
      min-height: 37px;

      .single-option {
        font-size: 13px;
        color: #38464c;
        font-weight: 600;
        height: 33px;
        line-height: 33px;
      }
      .field {
        height: 33px;
        border-radius: 3px;
        background-color: #ffffff;
        font-size: 13px;
        font-weight: 600;
        color: #38464c;
        border: 1px solid #b7c0c5;
        padding: 0 10px;
        margin: 0;
        background-clip: padding-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-sizing: content-box;
        background: none;
        -webkit-tap-highlight-color: transparent;
        text-transform: none;
        overflow: visible !important;
        width: calc(100% - 22px);
        position: relative;

        &:active,
        &:focus {
          border-color: #3b5e6e;
        }
        &:disabled {
          background-color: #f6f9fa;
          cursor: not-allowed;
        }
        &.loading {
          background-image: url('./assets/img/main-loading-icon.svg');
          background-size: 33px;
          background-repeat: no-repeat;
          background-position: right 0px;
        }
      }

      select {
        &.field {
          background-image: url('./assets/img/down-arrow-icon.svg');
          background-size: 11px 6px;
          background-repeat: no-repeat;
          background-position: right 9px top 15px;
          &:disabled {
            background-image: none;
          }
        }
      }

      textarea {
        &.field {
          padding: 11px 10px;
          min-height: 87px;
        }
      }

      &.text {
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        color: #38464c;
        line-height: 37px;
      }

      &.postfix {
        position: relative;

        .field {
          border-radius: 3px 0 0 3px;
          border-right: 0;
          width: calc(100% - 58px);
          float: left;
        }

        .fix {
          float: left;
          display: flex;
          width: 36px;
          height: 35px;
          border-radius: 0 3px 3px 0;
          border: 1px solid #b7c0c5;
          border-left: 0;
          background-color: #f6f9fa;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
          color: #5e737d;
        }
      }

      &.fields-group {
        position: relative;
        display: flex;
        .field {
          flex: 1;
          border-radius: 0;

          &:first-child {
            border-radius: 3px 0 0 3px;
          }

          &:last-child {
            border-radius: 0 3px 3px 0;
            border-left: 0;
          }
          float: left;

          &.country-code-select {
            width: 49px;
            flex: none;
          }
        }
      }
    }

    .error-message {
      display: none;
    }

    &.has-error {
      .form-control {
        & > .field {
          border-color: #d0021b !important;
        }
        & > .fix {
          border-color: #d0021b;
        }
      }
      .error-message {
        display: block;
      }
    }

    .hint {
      font-size: 10px;
      font-style: italic;
      margin: 1px 0 0 0;
      color: #5e737d;
    }
    .error {
      color: #d0021b;
    }
  }

  .form-group-sub-title {
    height: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3a484e;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 10px;

    .title {
      font-size: 16px;
      font-weight: 600;
      text-transform: none;
      color: #3a484e;
      margin: 0;
    }

    .placeholder-line {
      flex: 1;
      border-bottom: 1px solid #9da5a9;
      margin: 0 0 0 8px;
      opacity: 0.5;
    }

    .remove-task {
      width: 47px;
      height: 17px;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #d0021b;
      cursor: pointer;
    }
  }

  // Buttons
  .btn {
    height: 42px;
    padding: 11px 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    display: flex;
    box-sizing: border-box;

    &.loading {
      background-image: url('./assets/img/loading.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      font-size: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.btn-sm {
      padding: 9px;
      font-size: 12px;
    }

    &.btn-primary {
      background-color: #2d4d80;
      border-color: #2d4d80;
      color: #ffffff;

      &:hover {
        background-color: #1e355a;
        border-color: #1e355a;
      }
    }
    &.btn-default {
      border-color: #b7c0c5;
      color: #b7c0c5;
      background-color: #ffffff;

      &:hover {
        color: #2c4c7f;
        border-color: #2c4c7f;
      }
    }
    &.btn-primary-line {
      border-color: #2c4c7f;
      color: #2c4c7f;
      background-color: #ffffff;

      &:hover {
        color: #2c4c7f;
        border-color: #2c4c7f;
      }
    }

    &.btn-danger {
      background-color: #394247;
      border-color: #394247;
      color: #ffffff;
    }

    &.btn-130 {
      width: 130px;
      padding-left: 0;
      padding-right: 0;
    }

    &.btn-110 {
      width: 110px;
      padding-left: 0;
      padding-right: 0;
    }

    &.btn-ico {
      color: #2c4c7f;
      border-color: #2c4c7f;
      font-weight: 600;
      background-color: #ffffff;

      span {
        display: inline-block;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .print-icon {
        width: 15px;
        height: 14px;
        background-image: url('./assets/img/printer-icon.svg');
      }

      .invoice-icon {
        width: 12px;
        height: 14px;
        background-image: url('./assets/img/invoice-icon.svg');
      }

      .track-icon {
        width: 18px;
        height: 14px;
        background-image: url('./assets/img/track-icon.svg');
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  .btn-group-container {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > .left {
      display: flex;
      align-items: flex-start;
    }
    & > .right {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .link {
    font-weight: 600;
    color: #2d4d80;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #2d4d80;
    }
  }

  // Text styles
  .text-uppercase {
    text-transform: uppercase;
  }

  .hidden {
    display: none !important;
  }

  .confirmation-dialog-container {
    & > div {
      & > div {
        width: 250px;
        padding: 20px 25px;

        .body {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.57;
          color: #3a484e;
        }

        .button-container {
          display: flex;
          flex-direction: row;
          margin-top: 15px;

          .btn {
            height: 35px;
            flex: 1;
          }
        }
      }
    }
  }

  .DayPickerInput {
    width: 100%;

    .DayPickerInput-OverlayWrapper {
      position: absolute !important;
    }
    .DayPicker-Day {
      padding: 2px 5px;
    }
    .DayPicker-Weekday {
      padding: 2px 5px;
    }
  }
  .has-error {
    .DayPickerInput {
      .field {
        border-color: #d0021b;
      }
    }
  }

  .error-message {
    font-size: 11px;
    margin: 1px 0 0 0;
    color: #d0021b;
  }

  .loading {
    width: 50px;
    height: 50px;
    background-image: url('./assets/img/main-loading-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 0;
  }

  .main-loading-container {
    background-image: url('./assets/img/main-loading-icon.svg');
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .address-locator-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      width: calc(100% - 72px);
      height: calc(100vh - 68px);
      position: absolute;
    }
  }

  .unset-background-color {
    background-color: unset !important;
  }
}

@media only screen and (max-width: 1279px) {
  .hidden-md {
    display: none !important;
  }
}

@media only screen and (max-width: 959px) {
  .hidden-sm {
    display: none !important;
  }

  body {
    .main-container {
      padding-top: 60px;
      .content-container {
        width: calc(100% - 30px);
      }

      .banner-container {
        background-size: auto 100%;
      }
    }

    .form-group {
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 759px) {
  body {
    .main-container {
      &.payment-page {
        .content-container {
          width: calc(100% - 30px);
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .hidden-xs {
    display: none !important;
  }
}
