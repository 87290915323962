.login-dialog-container,
.login-page-container {
  & > div {
    & > div {
      width: 620px;
      padding: 33px 50px;
      position: relative;
      overflow: initial;

      .body {
        display: flex;
        flex-direction: row;
        width: 100%;

        .login-container {
          flex: 1;
          padding: 17px 50px 17px 0;
        }

        .otp-form-control {
          position: relative;

          .resend-otp-link {
            position: absolute;
            top: 10px;
            right: 9px;
            font-size: 10px;
            font-weight: 600;
            color: #2d4d80;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

#loginPasswordLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    font-size: 11px;
    flex: 0.9;
  }
}

@media only screen and (max-width: 959px) {
  .login-dialog-container {
    & > div {
      & > div {
        width: calc(100% - 40px);
        padding: 13px 20px;
        margin: 0;
        border-radius: 0;

        .body {
          flex-direction: column;

          & > div {
            &.login-container {
              flex: none;
              padding: 17px 0 25px 0;
            }

            .do-another-action {
              font-size: 13px;
              text-align: center;
              color: #5c717b;
              margin-top: 18px;
              margin-bottom: 0;
              display: block;

              .link {
                text-transform: uppercase;
                font-weight: bold;
              }
            }

            .button-container {
              display: flex;
              flex-direction: row;
              margin-top: 18px;

              .btn {
                flex: 1;
                height: 40px;
                padding: 1px 0 0 0;

                &.btn-default {
                  display: block;
                }
              }
            }
          }
        }

        .close {
          display: none;
        }
      }
    }
  }
}
