.reset-password-dialog-container {
  & > div {
    & > div {
      width: 344px;
      padding: 20px 22px 25px;
      box-sizing: border-box;
      position: relative;
      overflow: initial;
      .body {
        display: flex;
        flex-direction: row;
        width: 100%;

        .send-reset-email-container {
          flex: 1;

          .email-sent-img {
            background-image: url('../../assets/img/email-sent.svg');
            background-size: 100%;
            margin: 0 auto 10px;
            width: 37px;
            height: 30px;
          }

          .email-sent-text {
            width: 260px;
            text-align: center;
            color: #5c717b;
            margin: 0 auto 25px;
            font-size: 14px;
            font-weight: normal;
          }

          .email-sent-button {
            margin: 0 auto;
            width: 110px;
          }
        }

        .reset-password-container {
          flex: 1;

          .password-updated-img {
            background-image: url('../../assets/img/icons-8-checkmark.svg');
            background-size: 100%;
            margin: 0 auto 10px;
            width: 30px;
            height: 30px;
          }

          .password-updated-text {
            width: 260px;
            text-align: center;
            color: #5c717b;
            margin: 0 auto 31px;
            font-size: 14px;
            font-weight: normal;
          }

          .password-updated-button {
            margin: 0 auto;
            width: 110px;
          }
        }

        & > div {
          h2 {
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin: 0 0 4px 0;
            color: #3a484e;
            text-transform: uppercase;
          }

          .info {
            margin: 0 0 30px 0;
            font-size: 14px;
            color: #5c717b;
          }

          label {
            text-transform: capitalize;
          }

          .button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            .btn {
              height: 36px;
              padding: 1px 0 0 0;
              width: 110px;

              &.btn-default {
                display: none;
              }
            }
          }
        }
      }

      .close {
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/img/close-dialog-icon.svg');
        background-size: 100%;
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .reset-password-dialog-container {
    & > div {
      & > div {
        width: calc(100% - 40px);
        padding: 13px 20px;
        margin: 0;
        border-radius: 0;
        box-sizing: content-box;

        .body {
          flex-direction: column;

          & > div {
            &.send-reset-email-container {
              flex: none;
              padding: 17px 0 25px 0;
            }
            &.reset-password-container {
              flex: none;
              border-left: 0;
              padding: 30px 0 25px 0;
            }

            .button-container {
              display: flex;
              flex-direction: row;
              margin-top: 18px;

              .btn {
                flex: 1;
                height: 40px;
                padding: 1px 0 0 0;

                &.btn-default {
                  display: block;
                }
              }
            }
          }
        }

        .close {
          display: none;
        }
      }
    }
  }
}
